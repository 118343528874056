@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.alert-modal {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: default;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  color: #000;
  -webkit-animation-name: modal-did-appear;
          animation-name: modal-did-appear;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-duration: 300ms;
          animation-duration: 300ms;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out; }
  .alert-modal .spacer {
    flex: 1 1; }
  .alert-modal .alert-modal-dialog {
    width: 100%;
    background: #FFF;
    border-radius: 20px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    text-align: center;
    padding: 20px;
    -webkit-animation-name: modal-body-did-appear;
            animation-name: modal-body-did-appear;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-duration: 300ms;
            animation-duration: 300ms;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out; }
    .alert-modal .alert-modal-dialog .alert-modal-dialog-icon {
      width: 40px;
      height: 40px;
      margin: auto; }
    .alert-modal .alert-modal-dialog .alert-modal-dialog-title {
      font-size: large;
      font-weight: bold;
      display: block;
      margin-top: 10px; }
    .alert-modal .alert-modal-dialog .alert-modal-dialog-message {
      font-size: medium;
      margin-top: 10px;
      display: block; }
    .alert-modal .alert-modal-dialog .alert-modal-dialog-buttons {
      display: block;
      margin-top: 15px; }
      .alert-modal .alert-modal-dialog .alert-modal-dialog-buttons .alert-dialog-button {
        width: 100%;
        display: block;
        border-radius: 999px;
        background-color: #EFEFEF;
        color: #000;
        margin-top: 10px;
        cursor: pointer;
        transition: background-color;
        text-align: center;
        padding: 10px; }
        .alert-modal .alert-modal-dialog .alert-modal-dialog-buttons .alert-dialog-button:first-child {
          margin-top: 0; }
        .alert-modal .alert-modal-dialog .alert-modal-dialog-buttons .alert-dialog-button:hover {
          background-color: #DFDFDF; }
        .alert-modal .alert-modal-dialog .alert-modal-dialog-buttons .alert-dialog-button.success {
          background: #00CC00;
          color: #FFF; }
          .alert-modal .alert-modal-dialog .alert-modal-dialog-buttons .alert-dialog-button.success:hover {
            background-color: #00AA00; }
        .alert-modal .alert-modal-dialog .alert-modal-dialog-buttons .alert-dialog-button.danger {
          background: #CC0000;
          color: #FFF; }
          .alert-modal .alert-modal-dialog .alert-modal-dialog-buttons .alert-dialog-button.danger:hover {
            background-color: #AA0000; }

@-webkit-keyframes modal-body-did-appear {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.8);
            transform: scale(0.8); }
  80% {
    opacity: 1;
    -webkit-transform: scale(1.05);
            transform: scale(1.05); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes modal-body-did-appear {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.8);
            transform: scale(0.8); }
  80% {
    opacity: 1;
    -webkit-transform: scale(1.05);
            transform: scale(1.05); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }
@-webkit-keyframes modal-did-appear {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
@keyframes modal-did-appear {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/*# sourceMappingURL=AlertModal.css.map */

.header-component {
  padding: 30px 0;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center; }
  .header-component .logo {
    width: 180px;
    height: 50px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat; }
  .header-component .space {
    flex: 1 1; }
  .header-component .actions {
    text-align: right;
    font-size: large; }
    .header-component .actions a {
      display: inline-block;
      margin-left: 5px;
      padding: 5px 10px;
      font-size: medium;
      font-weight: bold;
      text-decoration: none;
      text-transform: uppercase; }
      .header-component .actions a:hover {
        text-decoration: underline; }
      .header-component .actions a.inverse {
        background-color: white;
        color: black;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: bold;
        font-size: small; }
        .header-component .actions a.inverse:hover {
          opacity: 0.8; }

/*# sourceMappingURL=HeaderComponent.css.map */

.screen-title{width:100%;display:flex;flex-direction:row;align-items:center}.screen-title .back-button{width:35px;height:35px;display:block;background-size:contain;background-position:center;background-repeat:no-repeat;margin-right:10px;cursor:pointer}.screen-title .text-area{flex:1 1}.screen-title .text-area .text-container{width:auto;height:auto;position:relative;display:block;font-size:xx-large;font-weight:bold;overflow:hidden}.screen-title .text-area .text-container .title-text{display:block}.screen-title .text-area .text-container .wiper{display:none}.screen-title.animated .text-area .text-container .title-text{opacity:1}.screen-title.animated .text-area .text-container .wiper{width:0;display:block;position:absolute;top:0;bottom:0;left:0;background-color:#fff}/*# sourceMappingURL=ScreenTitle.css.map */

.ep-table {
  width: 100%; }
  .ep-table thead {
    background: white;
    color: black;
    font-weight: bold; }
    .ep-table thead th {
      padding: 5px;
      border: 1px solid white;
      text-transform: uppercase;
      font-size: small; }
  .ep-table tbody {
    background-color: #BFC5D1; }
    .ep-table tbody tr {
      border: 1px solid white;
      transition: background-color 100ms, color 100ms; }
      .ep-table tbody tr:hover {
        color: white;
        background: #CC0033; }
      .ep-table tbody tr td {
        border: 1px solid white;
        padding: 5px; }
    .ep-table tbody .empty-message {
      text-align: center;
      font-weight: bold;
      text-transform: uppercase;
      font-size: small; }
  .ep-table.clickable tbody tr {
    cursor: pointer; }
  .ep-table.alternating tbody tr:nth-child(even) {
    background: #469FD9; }
    .ep-table.alternating tbody tr:nth-child(even):hover {
      background: #CC0033; }
  .ep-table .sortable {
    cursor: pointer;
    background-position: right center;
    background-repeat: no-repeat;
    padding-right: 20px; }

.ep-table-paginate-container {
  display: flex;
  flex-direction: row; }
  .ep-table-paginate-container .push {
    flex: 1 1; }

.ep-table-paginate {
  display: flex;
  flex-direction: row;
  border: 1px solid white;
  border-radius: 5px;
  overflow: hidden;
  color: white; }
  .ep-table-paginate .page-item, .ep-table-paginate .page-next, .ep-table-paginate .page-prev, .ep-table-paginate .page-gap {
    padding: 5px 10px;
    border-right: 1px solid white;
    cursor: pointer; }
  .ep-table-paginate .page-item {
    background: #333333; }
    .ep-table-paginate .page-item:hover {
      background: black; }
    .ep-table-paginate .page-item.active {
      font-weight: bold; }
  .ep-table-paginate .page-gap {
    cursor: default; }
  .ep-table-paginate .page-next, .ep-table-paginate .page-prev {
    background: #333333;
    color: white; }
    .ep-table-paginate .page-next:hover, .ep-table-paginate .page-prev:hover {
      background: black; }
  .ep-table-paginate .page-next {
    border: 0; }

/*# sourceMappingURL=TableComponent.css.map */

.loading-spinner {
  width: 180px;
  height: 30px;
  display: inline-block;
  position: relative; }
  .loading-spinner.inverted .loading-box {
    background: #333333; }
  .loading-spinner .loading-box {
    width: 30px;
    height: 30px;
    background: white;
    position: absolute;
    -webkit-animation-duration: 1200ms;
            animation-duration: 1200ms;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite; }
  .loading-spinner .hero-box {
    -webkit-animation-name: hero-box-movement;
            animation-name: hero-box-movement; }
  .loading-spinner .static-box-one {
    left: 30px;
    -webkit-animation-name: static-box-one-fade;
            animation-name: static-box-one-fade; }
  .loading-spinner .static-box-two {
    left: 75px;
    -webkit-animation-name: static-box-two-fade;
            animation-name: static-box-two-fade; }
  .loading-spinner .static-box-three {
    left: 120px;
    -webkit-animation-name: static-box-three-fade;
            animation-name: static-box-three-fade; }
@-webkit-keyframes hero-box-movement {
  0% {
    opacity: 0;
    left: 0; }
  10% {
    opacity: 1; }
  90% {
    opacity: 1; }
  100% {
    opacity: 0;
    left: 100%; } }
@keyframes hero-box-movement {
  0% {
    opacity: 0;
    left: 0; }
  10% {
    opacity: 1; }
  90% {
    opacity: 1; }
  100% {
    opacity: 0;
    left: 100%; } }
@-webkit-keyframes static-box-one-fade {
  0% {
    opacity: 0; }
  25% {
    opacity: 0; }
  26% {
    opacity: 1; }
  80% {
    opacity: 1; }
  100% {
    opacity: 0; } }
@keyframes static-box-one-fade {
  0% {
    opacity: 0; }
  25% {
    opacity: 0; }
  26% {
    opacity: 1; }
  80% {
    opacity: 1; }
  100% {
    opacity: 0; } }
@-webkit-keyframes static-box-two-fade {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  51% {
    opacity: 1; }
  80% {
    opacity: 1; }
  100% {
    opacity: 0; } }
@keyframes static-box-two-fade {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  51% {
    opacity: 1; }
  80% {
    opacity: 1; }
  100% {
    opacity: 0; } }
@-webkit-keyframes static-box-three-fade {
  0% {
    opacity: 0; }
  75% {
    opacity: 0; }
  76% {
    opacity: 1; }
  80% {
    opacity: 1; }
  100% {
    opacity: 0; } }
@keyframes static-box-three-fade {
  0% {
    opacity: 0; }
  75% {
    opacity: 0; }
  76% {
    opacity: 1; }
  80% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/*# sourceMappingURL=LoadingSpinner.css.map */

.ui-blocker {
  display: flex;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  align-items: center; }
  .ui-blocker .text-center {
    display: block;
    width: 100%; }

/*# sourceMappingURL=UIBlocker.css.map */

.login-component{height:100vh;display:flex;align-items:center}.login-component .logo{position:relative}.login-component .logo .image{position:absolute;display:block;top:0;bottom:0;left:0;right:0;background-size:contain;background-repeat:no-repeat;background-position:center}.login-component .logo .wiper{width:0;display:block;position:absolute;top:0;bottom:0;left:0;background-color:#fff}.login-component .login-box label{font-size:small;font-weight:bold;text-transform:uppercase}.login-component .full-width-button{width:100%}/*# sourceMappingURL=LoginComponent.css.map */

.guide-sort-component .guide-item {
  cursor: move;
  border-top: 2px solid rgba(0, 0, 0, 0); }
  .guide-sort-component .guide-item.drop-target {
    border-top: 2px solid #000; }

/*# sourceMappingURL=GuideSortComponent.css.map */

.link-sort-component .link-item {
  cursor: move;
  border-top: 2px solid rgba(0, 0, 0, 0); }
  .link-sort-component .link-item.drop-target {
    border-top: 2px solid #000; }

/*# sourceMappingURL=LinkSortComponent.css.map */

body {
  background: #BFC5D1;
  color: #000;
  font-family: 'Inter', sans-serif; }

.text-right {
  text-align: right; }

a {
  color: #000;
  text-decoration: underline; }
  a:hover {
    color: #000;
    text-decoration: none; }

.clickable {
  cursor: pointer; }

.card {
  color: black; }

.file-hide {
  width: 1px;
  height: 1px;
  overflow: hidden;
  display: block;
  position: absolute;
  left: -999px;
  top: -999px; }

.screen-search-icon {
  width: 1.2em;
  height: 1.6em;
  display: inline-block;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat; }

.app-content {
  padding: 20px;
  background: #fff; }

.ep-table-paginate-container {
  margin-top: 10px; }

.screen-title-container {
  border-bottom: 2px solid #000;
  align-items: center; }
  .screen-title-container .screen-actions {
    text-align: right; }
    .screen-title-container .screen-actions.animated {
      -webkit-animation-name: actions-did-appear;
              animation-name: actions-did-appear;
      -webkit-animation-duration: 400ms;
              animation-duration: 400ms;
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
      -webkit-animation-iteration-count: 1;
              animation-iteration-count: 1; }

.modal {
  display: block !important;
  color: black;
  background-color: rgba(0, 0, 0, 0.6); }
  .modal .modal-dialog .ep-table thead {
    background-color: #BFC5D1 !important;
    color: white !important; }
    .modal .modal-dialog .ep-table thead th {
      border-color: #BFC5D1 !important; }
  .modal .modal-dialog .ep-table tbody {
    background-color: white !important;
    color: black !important; }
    .modal .modal-dialog .ep-table tbody tr {
          /*border-color: $ui-background !important;

          &:hover {
            background-color: $ui-background-inverse-off !important;
          }*/ }
      .modal .modal-dialog .ep-table tbody tr td {
        border: 1px solid #BFC5D1 !important; }
  .modal .modal-dialog .ep-table.alternating tbody tr:nth-child(even) {
    background-color: #EFEFEF !important; }
    .modal .modal-dialog .ep-table.alternating tbody tr:nth-child(even):hover {
      background: #469FD9 !important; }
  .modal.animated .modal-dialog {
    -webkit-animation-name: screen-did-appear;
            animation-name: screen-did-appear;
    -webkit-animation-duration: 400ms;
            animation-duration: 400ms;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1; }

.ep-table {
  border: 2px solid #000; }
  .ep-table thead {
    background: #000; }
    .ep-table thead th {
      color: #fff;
      border: 0;
      padding: 8px 18px; }
  .ep-table tbody {
    background-color: #fff; }
    .ep-table tbody tr td {
      border: 0;
      padding: 8px 18px; }

.ep-table.alternating tbody tr:nth-child(even) {
  background: #eee; }

.title-text {
  font-weight: 800;
  margin-top: 8px;
  margin-bottom: 6px; }

.btn-light {
  background-color: #CC0033;
  border: 0;
  color: #fff;
  font-weight: 600; }

.login-box form {
  margin-top: 20px; }

.vendors-landing-component, .vendor-editor-component {
  background: #fff; }

.header-component {
  padding: 0; }

.header-component .actions a.inverse {
  background-color: #CC0033;
  color: #fff;
  font-weight: 800;
  border-radius: 6px;
  padding: 6px 12px; }

.ep-table-paginate .page-item.active {
  background: #CC0033; }

label {
  font-weight: 500; }

.form-control, .form-select {
  border: 2px solid #BFC5D1; }

.alert-modal .alert-modal-dialog .alert-modal-dialog-buttons .alert-dialog-button {
  background-color: #CC0033;
  color: #fff;
  font-weight: 600;
  border-radius: 6px; }

.screen-title .back-button {
  width: 30px;
  height: 30px; }

.header-component .actions a.active {
  background: rgba(0, 0, 0, 0.2); }

.header-component .actions a {
  padding: 36px 16px; }
  .header-component .actions a.active {
    background: rgba(0, 0, 0, 0.2); }

.button-icon {
  display: inline-block;
  width: 1.8rem;
  height: 1.8rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center; }

.image-preview {
  background: #EFEFEF;
  border: 1px solid #CCC;
  border-radius: 5px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

/*# sourceMappingURL=App.css.map */

